.container {
  max-width: 650px;
  margin: 0 auto;
  margin-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  box-sizing: border-box;
  color: var(--offBlack);
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.container b {
  font-weight: 600;
}

.info {
  background-color: var(--lightGreyBackground);
  border: var(--border);
  padding: 1em 0;
  border-radius: 1em;
  margin-bottom: 1.5em;
}

.info>div {
  margin: 15px;
  font-size: 1.2em;
}

.info>div:last-child {
  margin-bottom: 5px;
}

.historyRow {
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
}

.historyRow>div {
  margin-bottom: 0.6em;
  margin-right: 0.5em;
  width: 33%;
}

.historyRow>div:last-child {
  margin-right: 0;
}

.tableHeader {
  margin: 0 15px !important;
}

.tableHeader>div {
  font-weight: 500;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.header {
  font-weight: 600;
  margin: 0 !important;
  margin-left: 15px !important;
  font-size: 1.4em !important;
}

.rows {
  overflow: auto;
  flex-grow: 1;
}

.history {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.history a {
  font-weight: 500;
  color: #11428B;
  cursor: pointer;
}