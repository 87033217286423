.container {
  position: fixed;
  width: 100%;
  height: var(--topNavHeight);
  background-color: var(--offWhite);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.35);
  display: flex;
  z-index: 2;
}

.logo {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 1em;
  cursor: pointer;
}

.logo div {
  width: 40px;
  height: 40px;
  background-image: url('../images/logo.jpg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: contrast(0.95);
}

.logo span {
  font-weight: 500;
  font-size: 1.5em;
  padding-left: 0.25em;
}

.nav {
  height: 100%;
  width: 500px;
  flex-grow: 1;
  display: flex;
  justify-content: right;
  padding-right: 1em;
}

.nav>div {
  display: flex;
  align-items: center;
  padding: 0 1em;
}

.nav a {
  font-size: 1.25em;
  padding-left: 0.35em;
  text-decoration: none;
  color: rgb(44, 44, 44);
}

.nav a:visited {
  color: rgb(44, 44, 44);
}

.nav>div {
  background-color: var(--offWhite);
  cursor: pointer;
}

.nav>div:hover {
  background-color: #C8DEFF;
}

.nav>div:hover a {
  color: var(--offBlack);
}

.nav>div:hover .navIcon {
  filter: contrast(1);
}

.navIcon {
  width: 25px;
  height: 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: contrast(0.8);
}

.moon {
  background-image: url('../images/Moon.png');
  background-size: 88%;
}

.link {
  background-image: url('../images/Link.png');
}

.sun {
  background-image: url('../images/Sun.png');
}

.users {
  background-image: url('../images/admin.png');
}

.profile {
  background-image: url('../images/profile.png');
}

.bargraph {
  background-image: url('../images/bargraph.png');
}

.about {
  background-image: url('../images/about.png');
}

.logout {
  background-image: url('../images/logout.png');
}

.language {
  background-image: url('../images/language.png');
}

.activity {
  background-image: url('../images/activity.png');
}

.languagePicker {
  background-color: rgb(255, 255, 255);
  width: 150px;
  right: 55px;
  position: absolute;
  top: 55px;
  border-radius: 0 0 0.5em 0.5em;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.355);
  flex-direction: column;
  padding-top: 0.5em !important;
  cursor: auto !important;
}

.languagePicker>div {
  width: 100%;
  font-size: 1.25em;
  margin-bottom: 0.25em;
  padding: 0.4em 0.5em;
  border-bottom: 0px solid rgb(184, 184, 184);
  border-radius: 0.25em;
  cursor: pointer;
}

.languagePicker>div:hover {
  background-color: #C8DEFF;
}

.languagePicker:hover {
  background-color: rgb(255, 255, 255) !important;
}

.selected {
  background-color:  #C8DEFF !important;
}